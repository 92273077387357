<template>
  <v-card-text class="d-flex">
    <div style="width: 50%;overflow: hidden;margin-bottom: 10px;">
      <v-img
        v-if="hasImage"
        :src="localImage"
      ></v-img>
    </div>


    <!-- upload photo -->
    <div class="ml-4">
      <image-uploader
        :debug="1"
        :maxWidth="1024"
        :quality="0.8"
        :autoRotate="false"
        outputFormat="verbose"
        :preview="false"
        :className="['fileinput', { 'fileinput--loaded' : hasImage }]"
        capture="environment"
        accept="video/*,image/*"
        doNotResize="['gif', 'svg']"
        @input="inputImage"
        @onUpload="startUploadImage"
        @onComplete="endUploadImage"
      >
        <label for="fileInput" slot="upload-label">
          <figure>
            <v-icon size="64">
              {{ icons.mdiCameraOutline }}
            </v-icon>
          </figure>
          <span class="upload-caption">
            {{ uploaderBtnText }}
          </span>
          <figure @click.prevent="clearImage" v-if="hasImage">
            <v-icon size="64" class="mt-4">
              {{ icons.mdiTrashCanOutline }}
            </v-icon>
          </figure>
          <span class="upload-caption" @click.prevent="clearImage"  v-if="hasImage">
            Remove
          </span>
        </label>
      </image-uploader>
    </div>
  </v-card-text>
</template>

<script>
import {mdiCameraOutline, mdiTrashCanOutline,} from '@mdi/js'
import ImageUploader from 'vue-image-upload-resize'
import {computed} from '@vue/composition-api'

export default {
  components: {
    ImageUploader,
  },
  props: {
    image: {
      type: String,
      default: null,
    },
    errorMessages: {
      type: Array,
      required: true,
    },
  },

  setup(props, { emit }) {
    let localImage = computed(() => props.image)

    let hasImage = computed(() => props.image !== null)

    let uploaderBtnText = computed(() => props.image !== null ? 'Replace' : 'Click to upload')

    const inputImage = (file) => {
      localImage = file.dataUrl
      emit('update', file.dataUrl)
    }

    const clearImage = () => emit('clear')

    const startUploadImage = () => {}

    const endUploadImage = () => {}

    return {
      hasImage,
      localImage,
      uploaderBtnText,
      inputImage,
      clearImage,
      startUploadImage,
      endUploadImage,
      icons: {
        mdiTrashCanOutline,
        mdiCameraOutline,
      }
    }
  },
}
</script>

<style>
#fileInput {
  display: none;
}
</style>
