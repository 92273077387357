<template>
  <div>
    <wysiwyg v-model="html" />
  </div>
</template>

<script>
import {unref} from '@vue/composition-api'

export default {
  props: {
    html: {
      type: String,
      required: true,
    },
    errorMessages: {
      type: Array,
      required: true,
    },
  },

  setup(props) {
  },
}
</script>
